import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService {

  private readonly BASE_URL: string = this.HOST + '/ecommerce';

  constructor(
    private http: HttpClient
  ) {
    super();
   }

   getService(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/service/" + id , {})
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }
}
