<div fxLayout="row wrap" *ngIf="show">
    <div fxFlex="100">
        <mat-card fxLayout="row" class="breadcrumb light-block">
            <a *ngIf="router.url != '/'" (click)="home()" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">Inicio</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b>{{breadcrumb.name}}</b></span>
            </div> 
        </mat-card>
    </div> 
</div>

