
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { throwError } from 'rxjs';

export class BaseService{

    public HOST: string = environment.backendUrl;
    public token : string = "";
 

// METODOS PARA IMPLEMENTACION NUEVA

    public createAuthorizationHeader(): HttpHeaders {
        return new HttpHeaders({
            'Authorization': sessionStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
          });
    }

    public createLoginHeader(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          });
    }

    public extractData(res: any) {
		return res.result;
	}

	public handleError (error: HttpErrorResponse | any) {
		if (error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			console.log('An error occurred: ', error.message ? error.message : error.toString());
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
		}
		console.log('Raw error:', error);
		return throwError(error);
    }

// METODOS PARA IMPLEMENTACION NUEVA

    public extractDataFull(res: Response) {
        return res.json();
    }

    public extractHeaders(res: Response) {
        return res.headers;
    }

    public buildRequestParams(sort?: string, collection?: string, pager?: {pageIndex: number, pageSize: number}){
        
        let params = new HttpParams() ;

        if(sort != undefined){
            params = params.set('sort', sort);
        }
        if(collection != undefined){
            params = params.set('collection', collection);
        }

        if(pager != undefined){
            params = params.set('index', pager.pageIndex.toString());
            params= params.set('size', pager.pageSize.toString());
        }
        
        return params;
    }

    public static builderObject(json: {}): {}{
        let obj = {};
        for(var key in json){
            let innerObj = obj;
            let keySplits = key.split('.');

            for(var i = 0; i < keySplits.length-1; ++i){
                let keySplit = keySplits[i];
                if( !innerObj[keySplit] ) {
                    innerObj[keySplit] = {};
                }
                innerObj = innerObj[keySplit];
            }

            innerObj[keySplits[keySplits.length-1]] = (json[key] == '') ? null : json[key];
        }
        return obj;
    }

    public handleResponse(res: Response) {
        return res.ok;
    }

    
}
