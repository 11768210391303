<footer style="background: #45494c;" class="bg-primary footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="100" fxLayout="column" fxLayoutAlign="center center"> 
            <img class="responsive" src="assets/images/bottom/bottom_1280.png"/>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="py-3 border-bottom-mute theme-container">        
        <div fxFlex="100" fxFlex.gt-sm="15" fxFlex.sm="25"> 
            <h3 style="color: white;" class="col-title">Basics</h3>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Our Story</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Our Coffe</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Visit</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Events</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">FAQ</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">BLog</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="15" fxFlex.sm="25"> 
            <h3 style="color: white;" class="col-title">Shops</h3>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Coffee</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Brewin</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Essentials</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Subscriptions</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Gift Subscriptions</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Special Orders</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Redeem Your Gift</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Return Policy</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="15" fxFlex.sm="25"> 
            <h3 style="color: white;" class="col-title">Comunity</h3>
            <p class="mt-2"><a style="color: white;"href="javascript:void(0);" class="link secondary-color">About Us</a></p>
            <p class="mt-2"><a style="color: white;"href="javascript:void(0);" class="link secondary-color">Events</a></p>
            <p class="mt-2"><a style="color: white;"href="javascript:void(0);" class="link secondary-color">Giving Back</a></p>
            <p class="mt-2"><a style="color: white;"href="javascript:void(0);" class="link secondary-color">Careers</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="15" fxFlex.sm="25"> 
            <h3 style="color: white;" class="col-title">Contac Us</h3>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">000.0000</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">000.0000</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Location</a></p>
            <p class="mt-2"><a style="color: white;" href="javascript:void(0);" class="link secondary-color">Email</a></p>
        </div>
        
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="copyright secondary-color theme-container">
        <p style="color: white;" ngClass.xs="mt-1">2019 © Cafe Unido by Kiosko Creativo</p>
    </div>
</footer>