<div class="main-slider">
  <div class="swiper-container h-100" [swiper]="config">
    <div class="swiper-wrapper h-100">      
<div *ngFor="let slide of slides" class="swiper-slide">
       
        <div  class="slide-item swiper-lazy">
          <div fxLayout="column" fxLayoutAlign="center center" class="content">
            <img [src]="slide.image" alt="" class="responsive">
            
          </div> 
      </div>
      </div>
    </div>
    
  
  </div>
</div>