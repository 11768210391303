import { Component} from '@angular/core';
import { Settings, AppSettings } from '../../../app.settings';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/pages/home/home.service';


@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent {
  public showOptions:boolean = false;
  public settings: Settings;
  constructor(public appSettings:AppSettings, public homeService : HomeService, private activatedRoute: ActivatedRoute) { 
    this.settings = this.appSettings.settings; 
  }

  public changeTheme(theme){
    this.settings.theme = theme;       
  } 

  ngOnInit() {
    /* this.activatedRoute.params.subscribe(params =>{
      this.homeService.getService(params["service"]).subscribe(service =>{
        if(service.color != null || service.color != undefined){ // ESTO PARA COMPROBAR QUE SE HAYA SELECCIONADO UN COLOR
          this.changeTheme(service.color);
        }
      });
    }); */  
  } 
}